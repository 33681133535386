* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.aboutPage-page {
	min-height: 100vh;
	width: 100% !important;
}

.aboutPage-container-page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.aboutPage-first-pages {
	width: 100%;
	display: flex;
	background-color: #ffffff;
	flex-direction: row;
	padding-top: 30px;
}

.aboutPage-view-first-img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.aboutPage-img-first {
	width: 650px;
	height: 600px
}

.aboutPage-second-pages {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	margin-top: 50px;
	padding: 20px;
	width: 100%;
}

.aboutPage-content {
	margin-top: 20px;
	font-weight: 400;
	font-size: 18px;
	font-family: 'Roboto';
	color: #000606;
	text-align: center;
}

.aboutPage-second-heading-text {
	font-weight: 400;
	font-size: 24px;
	color: #556B2F;
	margin-top: 20px;
	font-family: 'Merriweather';
	text-align: center;
}

.para-aboutUsPage-container {
	width: 100%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
}

.paraFirst-aboutUsPage-container {
	padding: 10px;
	width: 80%;
}

@media only screen and (max-width: 768px) and (min-width: 280px) {
	.aboutPage-img-first {
		width: 300px;
		height: 300px;
	}

	.aboutPage-second-heading-text {
		font-size: 20px;
	}

	.para-aboutUsPage-container {
		padding: 1px;
	}

	.paraFirst-aboutUsPage-container {
		width: 100%;
	}

	.aboutPage-content {
		text-align: justify;
		font-size: 16px;
	}
}