* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contactUs-page {
  min-height: 100vh;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactUs-container-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contactUs-first-pages {
  width: 100%;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
}

.contactUs-heading {
  text-align: center;
  margin-bottom: 20px;
}

.contactus-first-heading {
  font-family: 'Merriweather';
  font-size: 28px;
  font-weight: 700;
  color: #000606;
}

.contact-form {
  width: 70%;
  max-width: 700px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.para-contactus-head {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  color: #000606;
  text-align: center;
  margin-top: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  color: #000606;
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  height: 48px;
  border: 1px solid #BABEBE;
  border-radius: 4px;
  padding: 9px;
  outline: none;
  background-color: #F8F8F8;
  font-size: 16px;
  font-family: 'Roboto';
}

.form-group textarea {
  height: 108px;
  resize: none;
  grid-column: span 2;
}

.form-group #phone {
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.button-group {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.submit-button-contactUS {
  width: 250px;
  height: 48px;
  background-color: #008080;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Roboto';
}

.submit-button-contactUS:hover {
  background-color: #006666;
}

.cancel-button {
  width: 288px;
  height: 48px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Roboto';
}

.cancel-button:hover {
  background-color: #f9f9f9;
}

.message-textarea-align {
  width: 100%;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .contact-form {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px;
  }

  .submit-button,
  .cancel-button {
    width: 100%;
    font-size: 14px;
    padding: 8px 16px;
  }
}