.footer {
  width: 100%;
}

.footer-top {
  width: 100%;
  color: azure;
  background: #000606;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: self-start;
  padding: 80px 0px;
}

.fooder-first-line {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: space-evenly;
  width: 100%;
}

.footer-first-content {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.fooder-second-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
  width: 40%;
}

.footer-last-para-text-page {
  width: 100%;
  margin-top: 15px;
}

.image-row-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
}

.image-rows {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  cursor: pointer;
  margin-right: 10px;
}

.footer-logo img {
  margin-left: 10px;
  width: 150px;
}

.footer-link .footer-sub-link {
  list-style-type: none;
  margin: 15px 0px;
  display: flex;
  flex-direction: row;
}

.footer-sub-content {
  list-style-type: none;
  margin: 15px 0px;
}

.footer-menu {
  width: 20%;
  margin: 0 10px;
}

.footer-services {
  width: 32%;
  margin: 0 20px;
}

.footer-product {
  width: 30%;
  margin: 0 30px;
}

.footer-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}

.footer-follow-us-on {
  width: 30%;
  margin: 0 10px;
}

.footer-contact {
  width: 40%;
  margin: 0 30px;
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 5px;
  background-color: #000606;
  width: 100%;
}

.logo-footer-align {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.footer-end-sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 5px;
  background-color: #000606;
  width: 90%;
  border-top: 0.5px solid #dddddd;
}

.end-footer-text-para {
  color: #ffffff;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
}

.footer-two-last-para {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20%;
}

.footer-btn {
  margin-top: 20px;
  background-color: #e8fa7e;
  padding: 10px 50px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.footer-icons {
  display: flex;
  align-items: center;
}

.footer-router-link {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  line-height: 1.5;
}
.t-link {
  text-decoration: none;
}
.footer-latter {
  background-color: yellow;
}

.footer-links {
  color: white;
}

.footer-sub-last-line {
  width: 100%;
}

.email-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background-color: #ffffff;
  padding-right: 10px;
  padding: 8px;
  border-radius: 8px;
  width: 85%;
}

.email-input {
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  width: 250px;
}

.send-button {
  padding: 10px 20px;
  background-color: teal;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.send-button:hover {
  background-color: #006666;
}

.footer-last-content-text {
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .footer-top {
    flex-direction: column;
  }

  .fooder-first-line {
    flex-direction: column;
  }

  .fooder-second-line {
    flex-direction: column;
  }

  .footer-logo {
    width: 130px;
  }

  .footer-menu {
    width: 100%;
    text-align: left;
    margin: 20px 0px 20px 0px;
    padding-left: 50px;
  }

  .footer-services {
    width: 100%;
    text-align: left;
    margin: 20px 0px 20px 0px;
    padding-left: 50px;
  }

  .footer-product {
    width: 100%;
    text-align: left;
    margin: 20px 0px 20px 0px;
    padding-left: 50px;
  }

  .footer-follow-us-on {
    width: 100%;
    text-align: left;
    margin: 20px 0px 20px 0px;
    padding-left: 50px;
  }

  .footer-contact {
    width: 90%;
    text-align: left;
    margin: 20px 0px 20px 0px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 991px) {
  .logo-footer-align {
    padding-left: 30px;
  }

  .fooder-second-line {
    padding-left: 40px;
  }

  .footer-first-content {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-router-link {
    font-size: 14px;
  }

  .end-footer-text-para {
    font-size: 14px;
  }

  .footer-two-last-para {
    width: 30%;
  }

  .footer-top {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .fooder-first-line {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-evenly;
    width: 90%;
  }

  .fooder-second-line {
    padding-top: 10px;
    width: 100%;
  }

  .fooder-second-line {
    width: 60% !important;
  }
}

@media only screen and (max-width: 430px) and (min-width: 375px) {
  .fooder-second-line {
    width: 100% !important;
  }

  .email-input {
    width: 200px;
  }

  .footer-end-sub {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
  }

  .footer-two-last-para {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 10px;
  }

  .send-button {
    padding: 10px 15px;
  }

  .email-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    width: 100%;
  }

  .footer-last-content-text {
    width: 100%;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 375px) and (min-width: 280px) {
  .fooder-second-line {
    width: 100% !important;
  }

  .email-input {
    width: 100px;
  }

  .footer-end-sub {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
  }

  .footer-two-last-para {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 10px;
  }

  .send-button {
    padding: 10px 15px;
  }

  .email-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    background-color: #ffffff;
    padding: 5px !important;
    border-radius: 8px;
    width: 100%;
  }

  .footer-last-content-text {
    width: 100%;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1028px) and (min-width: 991px) {
  .logo-footer-align {
    padding-left: 30px;
  }

  .fooder-second-line {
    padding-left: 40px;
  }

  .footer-first-content {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-router-link {
    font-size: 14px;
  }

  .end-footer-text-para {
    font-size: 14px;
  }

  .footer-two-last-para {
    width: 30%;
  }

  .footer-top {
    flex-direction: row;
    padding-bottom: 10px;
  }

  .fooder-first-line {
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: space-evenly;
    width: 70%;
  }

  .fooder-second-line {
    padding-top: 10px;
    width: 100%;
  }

  .fooder-second-line {
    width: 27%;
  }

  .email-form {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .send-button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .email-input {
    width: 140px;
  }
}
