.video-home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    height: 100% !important;
}

.sub-video-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 100% !important;
}

.video-view-row {
    width: 30%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 50px;
}

.video-img-first {
    width: 594.39px;
    height: 573.92px;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .video-img-first {
        width: 280px;
        height: 300px;
    }
    .sub-video-container {
        flex-direction: column;
    }
    .video-view-row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding-left: 0px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .video-img-first {
        width: 400px;
        height: 300px;
    }
}