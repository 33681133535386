* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.faq-main-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}

.faq-Sub-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: white;
  max-height: 100%;
  overflow: hidden;
}

.faq-body-page-container {
  max-width: 1200px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-heading {
  font-size: 28px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #000606;
  margin-bottom: 20px;
  text-align: center;
}

.faqMain-form-box-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.faq-accordion-container {
  width: 100%;
  display: flex;
}

.faq-accordion {
  padding: 10px;
  margin-bottom: 30px;
  width: calc(100% - 10px);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #000606;
}

.faq-answer {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #333;
}

.notes-container {
  width: 60%;
  padding-top: 20px;
}

.note-heading-text {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: red;
}

.note-para-text {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #333;
}

.faq-accordion-container-sub {
  width: 50%;
}
@media (max-width: 768px) {
  .faq-accordion {
    width: 100%;
  }

  .faq-heading {
    font-size: 24px;
  }

  .notes-container {
    width: 100%;
  }

  .faq-question {
    font-size: 18px;
  }

  .faq-answer {
    font-size: 14px;
  }

  .note-heading-text {
    font-size: 18px;
  }

  .note-para-text {
    font-size: 14px;
  }
  .faq-accordion-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }
  .faq-accordion-container-sub {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .faq-heading {
    font-size: 20px;
  }

  .faq-question {
    font-size: 16px;
  }

  .notes-container {
    width: 100%;
  }

  .faq-answer {
    font-size: 12px;
  }
  .note-heading-text {
    font-size: 16px;
  }

  .note-para-text {
    font-size: 12px;
  }
}
