* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.WorksPage-page {
	min-height: 100vh;
	width: 100% !important;
}

.WorksPage-container-page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.WorksPage-first-pages {
	width: 100%;
	display: flex;
	background-color: #ffffff;
	flex-direction: column;
	padding-top: 30px;
}

.workPage-Card-heading {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
}

.workPage-heading-container {
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 30px;
}

.workPage-heading-text {
	font-size: 34px;
	color: #000606;
	font-family: 'Merriweather', serif;
	font-weight: 600;
}

.steps-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
}

.step-card {
	width: 30%;
	height: 280px;
	background-color: #f9f9f9;
	border-radius: 10px;
	margin-bottom: 50px;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(48, 48, 48, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:hover {
	transform: scale(1.05);
	box-shadow: 0 8px 16px rgba(48, 48, 48, 0.2);
}

.step-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
}

.step-heading {
	font-family: 'Inter';
	font-size: 24px;
	color: #000606;
	margin-bottom: 10px;
	font-weight: 600;
}

.step-description {
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 18px;
	color: #000606;
	text-align: left;
	line-height: 25px;
	margin-top: 10px;
}

.span-text-description {
	font-size: 18px;
	font-family: 'Poppins';
	color: #000606;
	font-weight: 600;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
	.step-heading {
		font-size: 16px;
	}

	.span-text-description {
		font-size: 14px;
	}

	.step-description {
		font-size: 14px;
		text-align: justify;
		text-justify: inter-word;
	}

	.steps-container {
		width: 100%;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		flex-wrap: nowrap;
		align-items: center;
	}

	.workPage-heading-text {
		font-size: 20px;
		color: #000606;
		font-family: 'Merriweather', serif;
		font-weight: 600;
	}

	.step-card {
		width: 100%;
		height: auto;
		background-color: #f9f9f9;
		border-radius: 10px;
		margin-bottom: 50px;
		padding: 20px;
		box-shadow: 0 4px 8px rgba(48, 48, 48, 0.1);
	}
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
	.step-description {
		font-size: 16px;
	}

	.step-heading {
		font-size: 20px;
	}

	.span-text-description {
		font-size: 16px;
	}

	.step-card {
		width: 30%;
		height: 420px;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 991px) {
	.step-description {
		font-size: 16px;
	}

	.step-heading {
		font-size: 20px;
	}

	.span-text-description {
		font-size: 16px;
	}

	.step-card {
		width: 30%;
		height: 310px;
	}
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
	.step-description {
		font-size: 18px;
	}

	.step-heading {
		font-size: 22px;
	}

	.span-text-description {
		font-size: 18px;
	}

	.step-card {
		width: 30%;
		height: 320px;
	}
}