* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.testimonials-page {
  min-height: 100vh;
  width: 100%;
}

.testimonials-container-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, rgba(240, 244, 248, 0.8), rgba(240, 244, 248, 0.6));
  /* Subtle gradient background */
}

.testimonials-heading {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 40px;
}

.testimonials-heading h1 {
  font-family: 'Merriweather', serif;
  font-size: 36px;
  font-weight: 700;
  color: #000606;
  margin-bottom: 10px;
}

.testimonials-heading h1::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: #000606;
  margin: 10px auto 0;
}

.testimonials-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.testimonials-sub-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  gap: 40px;
}

.testimonial {
  width: 330px;
  height: 330px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for cards */
.testimonial:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Different background colors for each card */
.testimonial:nth-child(1) {
  background: linear-gradient(135deg, #f0f8ff, #a0d2eb);
}

.testimonial:nth-child(2) {
  background: linear-gradient(135deg, #e6e6e6, #bfbfbf);
}

.testimonial:nth-child(3) {
  background: linear-gradient(135deg, #ffebcd, #ffcc99);
}

.testimonial:nth-child(4) {
  background: linear-gradient(135deg, #ffe4e1, #ffb6c1);
}

.testimonial:nth-child(5) {
  background: linear-gradient(135deg, #f5f5dc, #e0e0a8);
}

.testimonial:nth-child(6) {
  background: linear-gradient(135deg, #e0ffff, #a0f0f8);
}

/* Text styling */
.testimonial p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000606;
  text-align: center;
}

.testimonial .quote {
  font-weight: 700;
  margin-bottom: 10px;
}

.testimonial .author {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #FF6347;
  background: linear-gradient(135deg, #f0f8ff, #e6e6e6);
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #FF6347;
  display: inline-block;
  position: relative;
}

.testimonial .author::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background: #FF6347;
  border-radius: 2px;
}

.testimonial .author-highlight {
  color: #000000;
  font-weight: 700;
  font-style: italic;
}

.share-story {
  margin-top: 30px;
  text-align: center;
}

.share-story p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000606;
}

.testimonialsPage-heading-text {
  font-family: 'Merriweather', serif;
  font-size: 36px;
  font-weight: 700;
  color: #000606;
  margin-bottom: 10px;
}

.testimonialsPage-heading-text::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: #000606;
  margin: 10px auto 0;
}

/* Animation for fading in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media only screen and (max-width: 1024px) and (min-width: 991px) {
  .testimonials-cards {
    gap: 15px;
  }

  .testimonial {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width:768px) {
  .testimonialsPage-heading-text {
    font-size: 24px;
  }

  .testimonial p {
    font-size: 14px;
  }

  .testimonial .quote {
    font-size: 16px;
  }

  .testimonial .author {
    font-size: 14px;
  }

  .testimonials-cards {
    gap: 10px;
  }

  .testimonial {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .testimonial {
    width: 280px;
    height: 280px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 280px) {
  .testimonials-sub-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
  }

  .testimonials-heading h1 {
    font-size: 20px;
  }

  .testimonial p {
    font-size: 12px;
  }

  .testimonial .quote {
    font-size: 14px;
  }

  .testimonial .author {
    font-size: 12px;
  }

  .testimonials-cards {
    flex-direction: column;
    align-items: center;
  }
}