* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.privacypolicy-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
}

.privacypolicy-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.privacypolicy-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.privacypolicy-body-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacypolicy-heading {
  font-size: 24px;
  font-weight: 700;
  color: black;
  text-align: center;
}

.privacypolicy-content {
  font-size: 18px;
  color: #333;
  font-size: 400;
  margin-bottom: 20px;
  text-align: justify;
}

.privacypolicy-content-container {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .privacypolicy-heading {
    font-size: 20px;
  }

  .privacypolicy-content {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .privacypolicy-heading {
    font-size: 18px;
  }

  .privacypolicy-content {
    font-size: 12px;
  }
}
