.advocacy-home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    width: 100%;
}

.sub-advocacy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
}

.advocacy-view-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.advocacy-img-first {
    width: 1240px;
    height: 699.13px;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
    .advocacy-img-first {
        width: 280px;
        height: 300px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .advocacy-img-first {
        width: 700px;
        height: 500px;
    }
}