* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-main-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
}

.body-container-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-page {
  min-height: 100vh;
  width: 100% !important;
}

.form-container-page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-first-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../assets/images/BannerBackgroundImg.png");
  background-size: cover;
  width: 100%;
  height: 660px;
}

.form-heading-text {
  color: #ffffff;
  font-size: 64px;
  font-family: "Merriweather";
  font-weight: 700;
  text-align: center;
}

.text-container-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.form-valid-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
}

.form-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 10px;
  align-items: center;
  width: 60%;
}

.form-navigation button {
  background-color: #008080;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
}

.form-navigation button:hover {
  background-color: #006666;
}

.form-body-content {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.head-component {
  height: 10%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body-component-form {
  height: 90%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-button-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 608px;
  margin-top: 50px;
}

.file-upload-container.dragging {
  border: 2px dashed #008080;
  background-color: #f0f0f0;
}

.form-header-text-align {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.form-header-text-align {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.form-content-row-align {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  color: #000606;
  width: auto;
}

.form-navigation.form-content-row-align.active {
  color: #008080;
}

.form-valid-container-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.first-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.input-container-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-bottom: 20px;
}

.input-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.upload-heading-text {
  font-family: "Inter";
  font-size: 20px;
  margin-top: 10px;
  font-family: 900;
  color: #000606;
}

.label-text-form {
  font-family: "Merriweather";
  font-weight: 500;
  font-size: 14px;
  color: #000606;
}

.input-container-name-conatiner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.error-container-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  bottom: 10px;
}

.error-message {
  color: red;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.browse-file-container-box {
  margin-top: 20px;
  border: 1px solid grey;
  padding: 6px;
  width: 120px;
  border-radius: 8px;
}

.browse-file-text-align {
  font-family: "Poppins";
  font-size: 16px;
  margin-top: 10px;
  font-family: 900;
  color: #000606;
}

.para-text-upload-span {
  color: #008080;
  font-size: 12px;
  font-family: "Inter";
  margin-left: 3px;
  font-weight: 600;
}

.para-text-upload {
  margin-top: 5px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  color: #babebe;
}

.thankYou-page-para {
  color: #000606;
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
}

.input-container-success {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 10px;
}

.video-react .video-react-big-play-button {
  align-items: center;
  justify-content: center;
  display: flex;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border: none;
  border-radius: 50%;
  background-color: #008080;
}

.video-react .video-react-big-play-button:hover {
  background-color: #008080;
}

.custom-clear-btn-form {
  width: 288px;
  height: 48px;
  border: 1px solid #006666;
  background-color: "transparent";
  color: #000606;
  font-family: "Inter";
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

.custom-clear-btn-form:hover {
  background-color: lightgray;
  border-color: "#BABEBE";
}

.file-upload-container {
  width: 608px;
  height: 240px;
  border: 4px dotted #babebe;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.file-upload-container.error-border {
  border-color: #fe1d1d;
}

.custom-button-ssuccess-btn {
  width: 288px;
  height: 48px;
  background-color: #008080;
  color: #ffffff;
  font-family: "Inter";
  border: none;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.recaptacharef-text-align {
  margin-left: 20px;
  width: 100%;
  margin-top: 20px !important;
}

.progress-line {
  width: 78px;
  height: 2px;
  opacity: 48%;
  background-color: transparent;
  transition: 0.3s;
}

.progress-line.active {
  background-color: #008080;
}

.card-upload-img-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  padding: 10px;
  border: 1px solid #babebe;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
}

.form-btn-box-container {
  width: 608px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .form-heading-text {
    font-size: 34px;
  }

  .recaptacharef-text-align {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
    justify-content: flex-start;
  }

  .card-upload-img-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 1px solid #babebe;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
  }

  .form-first-pages {
    height: 345px;
  }

  .first-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }

  .form-header-text-align {
    font-size: 16px;
  }

  .form-navigation {
    margin-bottom: 10px;
    width: 90%;
  }

  .custom-button-form-btn {
    width: 240px;
    height: 45px;
  }

  .form-button-content {
    gap: 90px;
  }

  .custom-clear-btn-form {
    width: 240px;
    height: 45px;
  }

  .file-upload-container {
    width: 470px;
    height: 195px;
  }

  .input-container-name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .progress-line {
    display: none;
  }

  .upload-heading-text {
    font-family: "Inter";
    font-size: 14px;
    margin-top: 5px;
    color: rgb(0, 6, 6);
  }

  .para-text-upload {
    margin-top: 5px;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 600;
    color: #babebe;
  }

  .browse-file-container-box {
    margin-top: 20px;
    border: 1px solid grey;
    padding: 5px;
    width: 100px;
    border-radius: 8px;
  }

  .browse-file-text-align {
    font-family: "Poppins";
    font-size: 14px;
    margin-top: 10px;
    font-family: 900;
    color: #000606;
  }

  .card-upload-img-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 1px solid #babebe;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
  }

  .recaptacharef-text-align {
    display: flex;
    align-items: flex-start;
    width: 100% !important;
    margin-right: 20px;
    justify-content: flex-start;
  }

  .form-content-row-align {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    gap: 5px;
    color: #000606;
    width: 100%;
  }

  .progress-line.active {
    background-color: #008080;
  }

  .form-heading-text {
    font-size: 16px;
  }

  .custom-button-ssuccess-btn {
    width: 188px;
    height: 40px;
    font-size: 16px;
  }

  .thankYou-page-para {
    font-size: 16px !important;
  }

  .form-header-text-align {
    font-size: 10px;
  }

  .first-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .form-first-pages {
    height: 145px;
  }

  .custom-button-form-btn {
    width: 100%;
    height: 40px;
  }

  .custom-clear-btn-form {
    width: 100%;
    height: 40px;
  }

  .form-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;
    margin-top: 20px;
  }

  .file-upload-container {
    width: 280px;
    height: 180px;
  }

  .form-valid-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }

  .form-body-content {
    width: 100%;
  }

  .form-btn-box-container {
    width: 304px;
  }

  .form-navigation {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (max-width: 1028px) and (min-width: 991px) {
  .form-heading-text {
    font-size: 34px;
  }

  .form-button-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
  }

  .card-upload-img-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 1px solid #babebe;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
  }

  .thankYou-page-para {
    font-size: 18px;
  }

  .first-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }

  .form-first-pages {
    height: 465px;
  }

  .form-body-content {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .form-body-content {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .input-container-name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }

  .form-header-text-align {
    font-size: 16px;
  }

  .form-navigation {
    margin-bottom: 10px;
    padding: 5px;
    width: 70% !important;
  }

  .custom-button-form-btn {
    width: 250px;
    height: 45px;
  }

  .custom-clear-btn-form {
    width: 250px;
    height: 45px;
  }

  .upload-heading-text {
    font-size: 18px;
  }

  .para-text-upload-span {
    font-size: 10px;
  }

  .para-text-upload {
    font-size: 10px;
  }

  .file-upload-container {
    width: 470px;
    height: 195px;
  }
}

.video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
  position: absolute;
  top: 2px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.custom-button-form-btn {
  width: 288px;
  height: 48px;
  border-radius: 8px;
  background-color: #008080;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 600;
  text-align: Center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.custom-button-form-btn:hover {
  background-color: #006666;
}
